<template>
  <div class="animated fadeIn">
    <PhotoEditorModal
      :modalTitle="$t('edit')"
      :show="editModal"
      @hide="editModal = false"
      :fileModel="form.featuredImage"
      :validHeight="editHeight"
      :validWidth="editWidth"
      :cropIds="[]"
      :activeMenu="[
        'flip',
        'crop',
        'resize',
        'draw',
        'shape',
        'icon',
        'text',
        'mask',
        'filter',
      ]"
      @onSave="(base64) => saveAndUpload(base64)"
    >
    </PhotoEditorModal>
    <b-form @submit.stop.prevent="onSubmit">
      <b-row>
        <b-col sm="8" md="8" xs="12" offset-sm="2">
          <b-card>
            <div slot="header">
              <strong>
                <i class="fa fa-files-o" />
                {{
                  category.id == 0 ? $t("newCategory") : $t("updateCategory")
                }}</strong
              >
            </div>
            <b-row>
              <b-col sm="12" class="text-center">
                <b-img
                  :src="getFeaturedImageSrc()"
                  class="img-radius"
                  alt="Featured"
                  thumbnail
                  rounded
                  @click="editFeaturedImage()"
                />
                <b-progress
                  :max="100"
                  height="1rem"
                  animated
                  style="margin: 2px"
                  v-if="form.featuredImage.uploadPercentage > 0"
                >
                  <b-progress-bar
                    :value="form.featuredImage.uploadPercentage"
                    variant="primary"
                  >
                    <span
                      >{{ $t("loading") }}:
                      <strong
                        >{{
                          form.featuredImage.uploadPercentage.toFixed(0)
                        }}%</strong
                      ></span
                    >
                  </b-progress-bar>
                </b-progress>
              </b-col>
              <b-col sm="12" class="text-center mt-1 mb-1">
                <input
                  type="file"
                  accept="image/*"
                  class="d-none"
                  ref="uploadFile"
                  v-on:change="() => fileLoaded()"
                />
                <BrandButton
                  :text="$t('browse')"
                  buttonIcon="fa fa-folder-open"
                  buttonVariant="primary"
                  @click="browse()"
                ></BrandButton>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6">
                <ValidationableTextBox
                  prename="category"
                  id="categoryName"
                  @onLostFocus="onCategoryLostFocus"
                  icon="fa fa-files-o"
                  v-model="$v.form.categoryName.$model"
                  :form="$v.form.categoryName"
                />
              </b-col>

              <b-col sm="6">
                <b-form-group>
                  <label for="parentCategory">{{ $t("parentCategory") }}</label>
                  <CategoriesDropdown
                    :categoryid="category.parentCategoryId"
                    @change="parentCategoryChanged"
                  ></CategoriesDropdown>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <ValidationableTextBox
                  prename="category"
                  id="symbol"
                  icon="fa fa-flag-checkered"
                  v-model="$v.form.symbol.$model"
                  :form="$v.form.symbol"
                />
              </b-col>

              <b-col sm="6">
                <ValidationableTextBox
                  prename="category"
                  id="styleCode"
                  icon="fa fa-unlock-alt"
                  v-model="$v.form.styleCode.$model"
                  :form="$v.form.styleCode"
                />
              </b-col>

              <b-col sm="6">
                <ValidationableTextBox
                  prename="category"
                  id="url"
                  @keydown="(e) => onUrlKeyDown(e)"
                  icon="fa fa-link"
                  v-model="$v.form.url.$model"
                  :form="$v.form.url"
                />
              </b-col>
              <b-col sm="6">
                <label for="seoKeywords">{{ $t("seoKeywords") }}</label>
                <b-form-tags
                  input-id="seoKeywords"
                  v-model="keyWords"
                  separator=",;"
                  add-on-enter
                  :placeholder="$t('seoKeywords')"
                  :addButtonText="$t('add')"
                ></b-form-tags>
              </b-col>

              <b-col sm="6">
                <ValidationableTextArea
                  prename="category"
                  id="seoDescription"
                  :rows="3"
                  v-model="$v.form.seoDescription.$model"
                  :form="$v.form.seoDescription"
                />
              </b-col>
              <b-col sm="3">
                <CheckBox
                  v-model="category.isStatic"
                  :defaultValue="category.isStatic"
                  :label="$t('isStatic')"
                ></CheckBox>
              </b-col>

              <b-col sm="3">
                <CheckBox
                  v-model="category.active"
                  :defaultValue="category.active"
                  :label="$t('active')"
                ></CheckBox>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" class="text-right">
                <div class="form-actions">
                  <BrandButton
                    buttonVariant="success"
                    buttonIcon="fa fa-save"
                    @click="save"
                    :text="$t('save')"
                  />

                  <BrandButton
                    buttonVariant="danger"
                    buttonIcon="fa fa-undo"
                    @click="back"
                    :text="$t('back')"
                  />
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import http from "../../shared/http";
import categoryValidator from "../../validations/category-validator";
import toast from "../../shared/toast-helper";
import generatorsHelper from "../../shared/utils/generators-helper";
import generalHelper from "../../shared/utils/general-helper";
import CategoriesDropdown from "../../widgets/CategoriesDropdown";
import ValidationableTextBox from "../../widgets/form/ValidationableTextBox";
import ValidationableTextArea from "../../widgets/form/ValidationableTextArea";
import PhotoEditorModal from "../../widgets/news/PhotoEditorModal";
import CheckBox from "../../widgets/form/CustomCheckBox";
import BrandButton from "../../widgets/form/CustomBrandButton";
import { validationMixin } from "vuelidate";
import stringHelper from "../../shared/string-helper";

export default {
  name: "CategoryAdd",
  components: {
    CategoriesDropdown,
    ValidationableTextBox,
    ValidationableTextArea,
    CheckBox,
    BrandButton,
    PhotoEditorModal,
  },
  mixins: [validationMixin],
  validations: categoryValidator.getValidations(),
  methods: {
    getFeaturedImageSrc() {
      if (
        this.form.featuredImage.localFile == null &&
        this.form.featuredImage.onlineFile == null
      ) {
        return "/img/noPic.png";
      } else if (this.form.featuredImage.localFile != null) {
        return this.form.featuredImage.localFile;
      } else {
        return this.form.featuredImage.onlineFile.fileName;
      }
    },
    editFeaturedImage() {
      if (this.form.featuredImage.onlineFile != null) {
        this.editModal = true;
      } else {
        toast.error(this.$t("notUploadedYet"));
      }
    },
    fileLoaded() {
      let file = this.$refs.uploadFile.files[0];
      if (generalHelper.isImageFile(file)) {
        this.form.featuredImage.uploadPercentage = 1;
        this.form.featuredImage.localFile = URL.createObjectURL(file);
        this.calculateImageSize();
        this.startUpload(file);
      } else {
        toast.error(this.$t("fileIsNotValid"));
      }
    },
    saveAndUpload(base64) {
      this.editModal = false;
      this.remove(false);
      var block = base64.split(";");
      var contentType = block[0].split(":")[1];
      var realData = block[1].split(",")[1];
      var blob = generatorsHelper.b64toBlob(realData, contentType);
      this.form.featuredImage.localFile = base64;
      this.calculateImageSize();
      this.startUpload(null, blob);
    },
    startUpload(file, blob = null) {
      let formData = new FormData();
      if (blob != null) {
        formData.append("file", blob, "image.jpg");
      } else {
        formData.append("file", file);
      }
      let self = this;
      http
        .post("/Files/upload", formData, {
          onUploadProgress: function (progressEvent) {
            this.form.featuredImage.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
        .then((response) => {
          this.form.featuredImage.onlineFile = response.data;
          this.form.featuredImage.uploadPercentage = 0;
        })
        .catch((e) => {
          if (blob == null) {
            self.remove();
          }
          if (!e.response.data.success) {
            toast.error(e.response.data.message);
          }
        });
    },
    browse() {
      this.$refs.uploadFile.click();
    },
    remove(withMessage = true) {
      if (this.form.featuredImage.onlineFile != null) {
        let model = {
          fileId: this.form.featuredImage.onlineFile.id,
        };
        http
          .post(
            `Files/deletefilebyid?fileId=${this.form.featuredImage.onlineFile.id}`
          )
          .then((response) => {
            if (withMessage) toast.success(response.message);
          });
      }
      this.form.featuredImage.localFile = null;
      this.form.featuredImage.onlineFile = null;
      this.form.featuredImage.uploadPercentage = 0;
      this.$refs.uploadFile.value = null;
    },
    calculateImageSize() {
      if (this.form === undefined) return;
      let img = new Image();
      img.onload = () => {
        this.editHeight = img.height;
        this.editWidth = img.width;
      };
      img.src =
        this.form.featuredImage.localFile == null
          ? this.form.featuredImage.onlineFile.fileName
          : this.form.featuredImage.localFile;
      //Load image size
    },
    parentCategoryChanged(parentCategory) {
      this.category.parentCategoryId = parentCategory?.id ?? null;
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;
      let model = { ...this.category, ...this.form };
      if (this.form.featuredImage.onlineFile != null) {
        model.featuredImageFileId = this.form.featuredImage.onlineFile.id;
      }
      let path = model.id > 0 ? "categories/update" : "categories/add";
      http.post(path, model).then((result) => {
        if (result && result.success) {
          toast.success(result.message);
          this.$router.back();
        }
      });
    },
    back() {
      this.$router.push({ path: "/category-list" });
    },
    onCategoryLostFocus() {
      if (this.form.categoryName != "" && this.form.url == "") {
        this.form.url = stringHelper.standardUrl(this.form.categoryName);
      }
    },
    onUrlKeyDown(e) {
      if (e.shiftKey || e.altKey || e.ctrlKey || e.code == "Space")
        e.returnValue = false;
      else e.returnValue = stringHelper.isValidNumChar(e.key);
    },
    getUpdateForm(categoryId) {
      let path = `categories/getbyid?categoryId=${categoryId}`;
      http.get(path).then((category) => {
        if (category) {
          this.category = category;
          this.form.categoryName = category.categoryName;
          this.form.symbol = category.symbol;
          this.form.styleCode = category.styleCode;
          this.form.url = category.url;
          this.keyWords = category.seoKeywords
            ? category.seoKeywords.split(",")
            : null;
          this.form.seoDescription = category.seoDescription;
          if (category.featuredImageFile != null) {
            this.form.featuredImage = {
              localFile: null,
              onlineFile: category.featuredImageFile,
              uploadPercentage: 0,
            };
            this.calculateImageSize();
          }
        }
      });
    },
  },
  data() {
    return {
      keyWords: [],
      editModal: false,
      editHeight: 0,
      editWidth: 0,
      form: {
        categoryName: "",
        symbol: "",
        styleCode: "",
        url: "",
        seoDescription: "",
        featuredImage: {
          localFile: null,
          onlineFile: null,
          uploadPercentage: 0,
        },
      },
      category: {
        id: 0,
        parentCategoryId: null,
        featuredImageFileId: null,
        categoryName: "",
        symbol: "",
        styleCode: "",
        url: "",
        seoKeywords: "",
        seoDescription: "",
        isStatic: false,
        active: true,
      },
    };
  },
  watch: {
    keyWords() {
      let keys = "";
      if (this.keyWords != null) {
        this.keyWords.forEach((element) => {
          keys = keys + "," + element;
        });
        this.category.seoKeywords = keys.substr(1);
      }
    },
  },
  created() {
    let categoryId = this.$route.params.id ? this.$route.params.id : 0;
    if (categoryId > 0) this.getUpdateForm(categoryId);
  },
};
</script>
<style scoped>
.img-radius {
  border-radius: 5px;
  height: 180px;
  width: 180px;
  object-fit: contain;
  background-color: white !important;
  cursor: pointer;
}
</style>