<template>
  <multiselect
    :options="list"
    :multiple="false"
    label="categoryName"
    track-by="id"
    @input="onSelect"
    v-model="selected"
    :placeholder="placeholder"
    :showLabels="false"
  >
    <template slot="noResult"> {{ $t("dataNotFound") }} </template></multiselect
  >
</template>

<script>
import http from "../shared/http";
import { getTranslation } from "../shared/language-helper";
export default {
  name: "categories-dropdown",
  props: {
    placeholder: {
      default: getTranslation("selectCategory"),
      type: String,
      required: false,
    },
    categoryid: { required: true },
    includeall: { required: false, default: false },
  },
  data() {
    return {
      list: [],
      selected: null,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    onSelect(item) {
      if (item) {
        this.$emit("change", item);
      } else {
        this.$emit("change", null);
      }
    },
    getList() {
      let path = "categories/getlist";
      http.get(path).then((response) => {
        if (response) {
          this.list = response;
          if (this.includeall) {
            this.list.unshift({
              id: null,
              categoryName: this.$t("all"),
            });
          }
          this.selected = this.list.find((i) => i.id == null);
          this.setSelected();
        }
      });
    },
    setSelected() {
      if (this.categoryid) {
        this.selected = this.list.find((i) => i.id == this.categoryid) || null;
      }
    },
  },
  watch: {
    categoryid() {
      this.setSelected();
    },
  },
};
</script>