import { required, minLength, maxLength } from "vuelidate/lib/validators";

function getValidations() {
    return {
        form: {
            categoryName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(50),
            },
            symbol: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(50),
            },
            styleCode: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(250),
            },
            url: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(250),
            },
            seoKeywords: {
            },
            seoDescription: {
                minLength: minLength(2),
                maxLength: maxLength(250),
            },
        },
    }
}

export default { getValidations };